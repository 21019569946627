<template>
  <div>
    <v-card elevation="0" :loading="serviceRequestProcessing">
      <template slot="progress">
        <v-progress-linear height="10" indeterminate></v-progress-linear>
        <p class="mt-4 text-center">Processing...</p>
      </template>
      <v-card-title class="my-0 py-0" v-if="!serviceRequestProcessing">
        <v-btn small class="orange-bg white--text" @click="printRequest">
          Print
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3" v-if="!serviceRequestProcessing">
        <v-container>
          <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Request #: </span>
            </v-col>
            <v-col cols="12" md="11">
              {{ serviceRequest.id }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Request Type: </span>
            </v-col>
            <v-col cols="12" md="11">
              {{ getServiceRequestTypeName(serviceRequest.requestType) }}
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Title: </span>
            </v-col>
            <v-col cols="12" md="11"
              >{{ serviceRequest.title }} {{ serviceRequest.id }}</v-col
            >
          </v-row> -->
          <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Due Date: </span>
            </v-col>
            <v-col cols="12" md="11">{{
              formatDate(serviceRequest.targetCompletionDate)
            }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Community: </span>
            </v-col>
            <v-col cols="12" md="11">{{ serviceRequest.communityName }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Opened By: </span>
            </v-col>
            <v-col cols="12" md="11"
              >{{ getCreatedBy(serviceRequest.createdBy) }}
              {{ formatDate(serviceRequest.created) }}</v-col
            >
          </v-row>
          <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Assigned To: </span>
            </v-col>
            <v-col cols="12" md="11">{{
              getAssignedTo(serviceRequest.assignedTo)
            }}</v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Request Type: </span>
            </v-col>
            <v-col cols="12" md="11">{{
              serviceRequest.requestType.name
            }}</v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Priority: </span>
            </v-col>
            <v-col cols="12" md="11"
              >{{ serviceRequest.priority.name }}
              <v-icon
                small
                :color="
                  serviceRequest.priority.name === 'Normal'
                    ? 'green'
                    : serviceRequest.priority.name === 'High'
                    ? 'red'
                    : 'blue'
                "
              >
                fas fa-circle
              </v-icon></v-col
            >
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <div>
                <span class="font-weight-bold">Location: </span>
              </div>
              {{ serviceRequest.location }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div>
                <span class="font-weight-bold">Description: </span>
              </div>
              {{ serviceRequest.description }}
            </v-col>
          </v-row>
          <v-row v-if="serviceRequest.isNeighborhoodLoginRequest">
            <v-col cols="12" md="1">
              <span class="font-weight-bold">HOA or Individual: </span>
            </v-col>
            <v-col cols="12" md="11">{{
              serviceRequest.isHoaRequest
                ? "Association Request"
                : "Individual Home - Extra Service"
            }}</v-col>
          </v-row>
          <v-row v-if="serviceRequest.isNeighborhoodLoginRequest">
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Neighborhood Name: </span>
            </v-col>
            <v-col cols="12" md="11">{{
              serviceRequest.neighborhoodName
            }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Contact Name: </span>
            </v-col>
            <v-col cols="12" md="11">{{ serviceRequest.contactName }}</v-col>
          </v-row>
          <v-row v-if="serviceRequest.isNeighborhoodLoginRequest">
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Home Address: </span>
            </v-col>
            <v-col cols="12" md="11">{{ serviceRequest.homeAddress }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Contact Phone: </span>
            </v-col>
            <v-col cols="12" md="11">{{ serviceRequest.contactPhone }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Contact Email: </span>
            </v-col>
            <v-col cols="12" md="11">{{ serviceRequest.contactEmail }}</v-col>
          </v-row>
          <v-row v-if="serviceRequest.isNeighborhoodLoginRequest">
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Preferred Contact Method: </span>
            </v-col>
            <v-col cols="12" md="11">{{
              serviceRequest.isEmailPreferred ? "Email" : "Phone"
            }}</v-col>
          </v-row>
          <v-row v-if="serviceRequest.attachments">
            <v-col cols="12" md="1">
              <span class="font-weight-bold">Attachments </span>
            </v-col>
            <v-col>
              <div
                v-for="(item, i) in serviceRequest.attachments.$values"
                :key="'c' + i"
              >
                <a :href="item.fileUrl">{{ item.fileName }}</a>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          v-for="(item, i) in serviceRequest.comments.$values"
          :key="'c' + i"
        >
          <hr style="background-color: #df6526; height: 1px;" />
          <v-row>
            <v-col class="green--text"
              >{{ getCreatedBy(item.createdBy) }}
              {{ formatDate(item.created) }}</v-col
            >
          </v-row>
          <v-row>
            <v-col>{{ item.description }}</v-col>
          </v-row>
        </v-container>
        <v-container>
          <hr style="background-color: #df6526; height: 1px;" />
        </v-container>
        <!-- <hr style="background-color: #df6526; height: 1px;" /> -->
        <v-card id="updateRequestForm" elevation="0" outlined color="#f1f1f1">
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-radio-group
                      v-model="requestStatus"
                      row
                      dense
                      :disabled="serviceRequest.isCompleted"
                    >
                      <v-radio
                        label="New"
                        color="primary"
                        value="new"
                        class="v-radio-no-margin"
                        :disabled="!serviceRequest.isNew"
                        v-show="
                          !serviceRequest.isCompleted && serviceRequest.isNew
                        "
                      ></v-radio>
                      <v-radio
                        label="Open"
                        color="primary"
                        value="open"
                        class="v-radio-no-margin"
                        v-show="
                          !serviceRequest.isCompleted &&
                            !serviceRequest.isInProcess
                        "
                      ></v-radio>
                      <v-radio
                        label="In-Process"
                        color="primary"
                        value="inProcess"
                        class="v-radio-no-margin"
                        v-show="!serviceRequest.isCompleted"
                      >
                      </v-radio>
                      <v-radio
                        label="On Hold"
                        color="primary"
                        value="onHold"
                        class="v-radio-no-margin"
                        v-show="!serviceRequest.isCompleted"
                      >
                      </v-radio>
                      <v-radio
                        label="Complete"
                        color="primary"
                        value="complete"
                        class="v-radio-no-margin"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-textarea
                      v-model="newRequest.description"
                      label="* Update Service Request"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      :loading="loadingServiceRequestTypes"
                      v-model="requestType"
                      :items="requestTypeItems"
                      item-text="name"
                      item-value="id"
                      label="* Choose type..."
                      :rules="[v => !!v || 'Type is required']"
                      required
                    >
                    </v-select>
                    <v-checkbox
                      v-model="newRequest.isInternal"
                      label="Internal only"
                    ></v-checkbox>
                  </v-col>
                </v-row> -->

                <v-row>
                  <v-col cols="12" md="12">
                    <v-radio-group v-model="requestType" label="* Choose Type">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="4"
                          v-for="(requestTypeItem, i) in requestTypeItems"
                          :key="'request_type_col_' + i"
                        >
                          <v-radio
                            :value="requestTypeItem.id"
                            :label="requestTypeItem.name"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                    <v-checkbox
                      v-model="newRequest.isInternal"
                      label="Internal only"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4">
                    <div
                      v-for="attachment in attachmentsObj"
                      cols="12"
                      class="darggable-col"
                      :key="attachment.id"
                    >
                      <MediaUploader
                        class="pb-5"
                        uploadType="photo"
                        :key="attachment.id"
                        :id="`multiFilePost_${attachment.id}`"
                        :index="attachment.id"
                        :accept="accept"
                        :multiple="true"
                        :extensions="extensions"
                        :sizeImage="10485760"
                        :sizeVideo="104857600"
                        :fileId.sync="attachment.fileId"
                        :file-url.sync="attachment.url"
                        :isUploading.sync="isFileUploading"
                        @onMultipleUploaded="onMultipleAddFile"
                        @onChange="onChange"
                        @onDeleteFile="onDeleteFile"
                      />
                    </div>
                  </v-col>
                </v-row>

                <!-- <v-row>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="priority"
                      :items="priorityItems"
                      item-text="name"
                      item-value="id"
                      label="* Choose priority..."
                      required
                    ></v-select>
                  </v-col>
                </v-row> -->
                <!-- <v-row v-if="!serviceRequest.isNeighborhoodRequest">
                  <v-col cols="12" md="6">
                    <v-textarea
                      v-model="newRequest.location"
                      label="Please indicate the residence address, address in close proximity or the location in the community (i.e. entrance, pool area, end of Whitehosrse dr., etc.) relating to this service request:"
                    ></v-textarea>
                  </v-col>
                </v-row> -->

                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="assignedTo"
                      :items="ynTeamItems"
                      item-text="fullName"
                      item-value="id"
                      label="* Assigned to"
                      required
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <p style="font-size: larger; font-weight: bold;">
                      * Indicates required fields
                    </p>
                  </v-col>
                </v-row>
                <v-btn
                  class="orange-bg white--text"
                  @click="saveServiceRequest"
                >
                  Update Service Request</v-btn
                >
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import { GET_PAGED, POST_DATA, API_USERS } from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  POST_DATA,
  API_EMPLOYEES,
  API_SERVICE_REQUESTS,
  API_SERVICE_REQUEST_TYPES
  // API_SERVICE_REQUEST_PRIORITIES
} from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";
import moment from "moment";

export default {
  components: {
    MediaUploader
  },
  data: () => ({
    isLoading: false,
    loading: false,
    loadingServiceRequestTypes: false,
    serviceRequestProcessing: false,
    serviceRequest: {},
    requestStatus: "open",
    newRequest: {
      communityName: "",
      title: "",
      requestType: "",
      description: "",
      isInternal: false,
      attachments: [],
      // priority: "",
      assignedTo: "",
      targetCompletitioDate: null,
      location: ""
    },
    menu1: false,
    completionDate: null,
    formattedCompletionDate: null,
    requestType: "",
    requestTypeItems: [
      // {
      //   name: "Maintanence",
      //   value: "Maintanence"
      // },
      // {
      //   name: "Landscape Installation",
      //   value: "Landscape Installation"
      // },
      // { name: "Irrigation", value: "Irrigation" },
      // { name: "Plant/Turf Health", value: "Plant/Turf Health" },
      // { name: "Storm/Other Damage", value: "Storm/Other Damage" },
      // { name: "Resident Request", value: "Resident Request" },
      // { name: "Customer Complaint", value: "Customer Complaint" },
      // { name: "QA", value: "QA" },
      // { name: "New Opportunity", value: "New Opportunity" },
      // { name: "Other", value: "Other" },

      { name: "General Service", value: "General Service" },
      { name: "Irrigation", value: "Irrigation" },
      { name: "Storm/Other Damage", value: "Storm/Other Damage" },
      { name: "Request for proposal", value: "Request for proposal" }
    ],

    ynTeamItems: [],

    // priority: "",
    // priorityItems: [
    //   { name: "Low", value: "Low" },
    //   { name: "Normal", value: "Normal" },
    //   { name: "High", value: "High" }
    // ],
    assignedTo: null,
    attachmentsObj: [{ id: 1, url: null }],
    isFileUploading: false
  }),
  props: {
    requestId: {
      type: [Number, String],
      default: 0
    }
  },
  watch: {
    completionDate: function() {
      this.formattedCompletionDate = this.formatDate(this.completionDate);
    }
  },
  async mounted() {},
  async created() {
    await this.getServiceRequestTypes();
    await this.getRecipients();
    // await this.getServiceRequestPriorities();
    await this.getServiceRequest();

    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Service Requests", route: "/service-requests" },
      { title: "Update Service Request" }
    ]);
  },
  methods: {
    printRequest() {
      window.print();
    },
    getServiceRequestTypeName(requestType) {
      if (requestType) return requestType.name;
      return "";
    },
    getAssignedTo(assignedTo) {
      return assignedTo?.fullName;
    },
    getCreatedBy(createdBy) {
      return createdBy?.fullName;
    },
    async getServiceRequest() {
      this.loading = true;
      this.serviceRequestProcessing = true;
      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_SERVICE_REQUESTS,
          id: this.requestId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.serviceRequest = response.data;

            self.completionDate = new Date(
              self.serviceRequest.targetCompletionDate
            )
              .toISOString()
              .substr(0, 10);

            self.requestType = self.serviceRequest.requestType.id;

            let comments = self.serviceRequest.comments.$values;
            if (comments.length > 0) {
              let lastComment = comments.sort((a, b) => {
                return new Date(b.created) - new Date(a.created);
              })[0];
              self.assignedTo = lastComment.assignedTo?.id;
              self.priority = lastComment.priority.id;

              self.completionDate = new Date(lastComment.targetCompletionDate)
                .toISOString()
                .substr(0, 10);

              self.serviceRequest.isOpen = lastComment.isOpen;
              self.serviceRequest.isInProcess = lastComment.isInProcess;
              self.serviceRequest.isOnHold = lastComment.isOnHold;
              self.serviceRequest.isCompleted = lastComment.isCompleted;

              self.requestType = lastComment.requestType.id;
            } else {
              self.assignedTo = self.serviceRequest.assignedTo?.id;
              self.priority = self.serviceRequest.priority.id;
            }
            if (self.serviceRequest.isOpen) {
              self.requestStatus = "open";
            } else if (self.serviceRequest.isInProcess) {
              self.requestStatus = "inProcess";
            } else if (self.serviceRequest.isOnHold) {
              self.requestStatus = "onHold";
            } else if (self.serviceRequest.isNew) {
              self.requestStatus = "new";
            } else {
              self.requestStatus = "complete";
            }
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          self.loading = false;
          self.serviceRequestProcessing = false;
        });
    },
    // async getServiceRequestPriorities() {
    //   this.loading = true;
    //   this.isLoading = true;
    //   let self = this;
    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: API_SERVICE_REQUEST_PRIORITIES
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         self.priorityItems = response.data.$values;
    //       } else {
    //         self.$snackbar.showMessage({
    //           content: response.data || "Something went wrong!",
    //           color: "red"
    //         });
    //       }
    //       self.loading = false;
    //       self.isLoading = false;
    //     });
    // },
    async getServiceRequestTypes() {
      this.loading = true;
      this.isLoading = true;
      this.loadingServiceRequestTypes = true;
      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_SERVICE_REQUEST_TYPES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.requestTypeItems = response.data.$values;
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          self.loading = false;
          self.isLoading = false;
          self.loadingServiceRequestTypes = false;
        });
    },
    saveServiceRequest() {
      this.newRequest.communityId = this.serviceRequest.communityId;

      this.newRequest.parentId = this.serviceRequest.id;
      this.newRequest.isNew = this.requestStatus === "new";
      this.newRequest.isOpen = this.requestStatus === "open";
      this.newRequest.isInProcess = this.requestStatus === "inProcess";
      this.newRequest.isOnHold = this.requestStatus === "onHold";
      this.newRequest.isCompleted = this.requestStatus === "complete";
      this.newRequest.skipNotification =
        `${process.env.VUE_APP_SKIP_NOTIFICATIONS}` == "1";

      let requestTypeObj = this.requestTypeItems[0];
      if (
        this.requestTypeItems.filter(el => el.id === this.requestType).length >
        0
      )
        requestTypeObj = this.requestTypeItems.filter(
          el => el.id === this.requestType
        )[0];

      this.newRequest.requestType = {
        id: requestTypeObj.id,
        name: requestTypeObj.name
      };

      this.newRequest.title = this.newRequest.requestType.name;

      // we don't need priority now
      // let priorityObj = this.priorityItems[0];
      // if (this.priorityItems.filter(el => el.id === this.priority).length > 0)
      //   priorityObj = this.priorityItems.filter(
      //     el => el.id === this.priority
      //   )[0];

      // this.newRequest.priority = {
      //   id: priorityObj.id,
      //   name: priorityObj.name
      // };

      let assignedToObj = this.ynTeamItems[0];
      if (this.ynTeamItems.filter(el => el.id === this.assignedTo).length > 0)
        assignedToObj = this.ynTeamItems.filter(
          el => el.id === this.assignedTo
        )[0];

      this.newRequest.assignedTo = {
        id: assignedToObj.id
      };
      this.newRequest.targetCompletionDate = moment.utc(this.completionDate);

      this.newRequest.attachments = this.attachmentsObj
        .filter(i => i.url && i.url.length > 0)
        .map(i => {
          return { id: i.fileId, fileUrl: i.url };
        });
      this.newRequest.attachments = this.newRequest.attachments.filter(
        (item, index) => this.newRequest.attachments.indexOf(item) === index
      ); //remove duplicates

      if (
        !this.newRequest.assignedTo ||
        // !this.newRequest.title ||
        !this.newRequest.description ||
        // !this.newRequest.priority ||
        !this.newRequest.requestType
      ) {
        this.snackbarContent =
          "Please, populate all required fields (*) on the form";
        this.snackbarColor = "red";
        this.$snackbar.showMessage({
          content: this.snackbarContent,
          color: this.snackbarColor
        });
        return;
      }

      this.serviceRequestProcessing = true;
      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_SERVICE_REQUESTS}`,
          data: this.newRequest
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent =
              "Service Request has been updated successfully";
            self.snackbarColor = "green";

            self.$router.push({
              name: "ServiceRequests"
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }

          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });

          self.serviceRequestProcessing = false;
        });

      // this.saveCustomer();
    },
    async getRecipients() {
      // let comunityItems = dataModels.communityDetails;
      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_EMPLOYEES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.ynTeamItems = response.data;
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    },
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    }
  }
};
</script>
<style lang="scss">
@media print {
  #kt_aside,
  #kt_header,
  #kt_subheader,
  #syncscape_communityInfo,
  #updateRequestForm {
    display: none !important;
  }
}
</style>
